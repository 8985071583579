<template>
  <div class="container footer">

    <div class="content" style="bottom: 0;">
      <div class="left">
        <div class="left-title">
          <div>北京数字精准广告有限公司</div>
          <p style="text-align: left;"> 北京数字精准广告有限公司，是一家以自有数据源和技术研发
            为核心，为全行业客户提供营销传播、广告投放、数据服务的技术服务商。凭借高质量的数据源、领先的技术、专
            业的服务，多年来已在电影发行、综合电商、3C数码等众多领域广受认可。 </p>
        </div>
        <div class="leftBox" id="leftBox">
          <ul style="margin-bottom: .2083rem;">
            <li>首页</li>
            <li @click="toArea('index', 0, 0)">产品</li>
            <li @click="toArea('index', 0, 1)">解决方案</li>
            <li @click="toArea('index', 0, 2)">技术与服务</li>
            <li @click="toArea('index', 0, 3)">合作客户</li>
            <!-- <li   @click="toArea('index',0,4)">联系我们</li> -->
          </ul>
          <ul style="margin-bottom: .2083rem;">
            <li>营销服务</li>
            <li @click="toArea('service', 1, 0)">精准PR整合传播</li>
            <li @click="toArea('service', 1, 1)">智能影视宣发平台</li>
            <li @click="toArea('service', 1, 2)">AI智能广告投放</li>
          </ul>
          <ul style="margin-bottom: .2083rem;">
            <li>应用场景</li>
            <li @click="toArea('sence', 2, 0)">人群策略</li>
            <li @click="toArea('sence', 2, 1)">地理位置</li>
            <li @click="toArea('sence', 2, 2)">数据分析</li>
          </ul>
          <ul style="margin-bottom: .2083rem;">
            <li>技术优势</li>
            <li @click="toArea('technology', 3, 0)">独立一方数据源</li>
            <li @click="toArea('technology', 3, 1)">专业投放能力</li>
            <li @click="toArea('technology', 3, 2)">精准人群洞察</li>
            <li @click="toArea('technology', 3, 3)">跨媒体联合频控</li>
          </ul>
          <ul>
            <li>服务优势</li>
            <!-- <li  @click="toArea('goods',4,0)">用专业和服务提升营销效果</li> -->
            <li @click="toArea('goods', 4, 0)">专业服务</li>
            <li @click="toArea('goods', 4, 1)">案例展示</li>
          </ul>
          <ul>
            <li>关于我们</li>
            <li @click="toArea('about', 5, 0)">公司简介</li>
            <li @click="toArea('about', 5, 1)">数据驱动营销</li>
            <li @click="toArea('about', 5, 2)">数据安全与隐私协议</li>
          </ul>
        </div>
      </div>

      <div class="right pc">
        <div class="right-title">联系方式</div>
        <div class="right-box">
          <p class="tel">{{ obj.mobile }}</p>
          <p class="email"><i class="el-icon-message" style="margin-right: .0156rem;"></i>
            <span>邮箱:{{ obj.site_admin_email }}</span> </p>
          <p class="address"><i class="el-icon-map-location" style="margin-right: .0156rem;"></i>
            <span>地址:{{ obj.address }}</span> </p>
        </div>
        <div class="ewm" style="opacity: 1;">
          <img src="../assets/index/ewm.png" style="opacity: 1;" alt="">
          <div style="opacity: .6;">在线客服</div>
        </div>
      </div>

      <div class="right mobile el-row">
        <div class="el-col-14">
          <div class="right-title">联系方式</div>
          <div class="right-box">
            <p class="tel">{{ obj.mobile }}</p>
            <p class="email"><i class="el-icon-message" style="margin-right: .0156rem;"></i>
              <span>邮箱:{{ obj.site_admin_email }}</span> </p>
            <p class="address"><i class="el-icon-map-location" style="margin-right: .0156rem;"></i>
              <span>地址:{{ obj.address }}</span> </p>
          </div>
        </div>
        <div class="el-col-10">
          <div class="ewm" style="opacity: 1;">
            <img :src="obj.wechat" style="opacity: 1;" alt="">
            <div style="opacity: .6;">在线客服</div>
          </div>
        </div>
      </div>
    </div>
    <main class="Copyright" style="
     width: 100%;border-top: 1px solid rgba(255, 255, 255,.1);text-align: center;
      color: white;font-size: .0729rem;opacity: .6;">Copyright 2024.版权所有 北京数学精准广告有限公司 京icp备 18040123号-1.京公网安备
      11010802027494</main>
  </div>
</template>

<script>
import { navs } from '@/api/nav'
import {bottom} from '@/api/bottom'
export default {
  data() {
    return {
      bottomList: [
        {
          id: 0,
          title: '首页',
        }
      ],
      obj:{}
    }
  },
  created() {
    this.getBottom()
    this.getom()
  },
  methods: {
    async getBottom() {
      const { data: res } = await navs()
      console.log(res, 'bottom 的数据');
      if (res.code == 1) {
        // this.bottomList=res.data
        // console.log(this.bottomList);
      }
    },
    toArea(p, i, index) {
      this.$nextTick(() => {
        this.$emit('gotosection', index, p, i)
      })
    },
    async getom() {
      const { data: res } = await bottom()
      this.obj=res.data
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
}

.container {
  bottom: 0;
  position: relative;
  background-color: #13161E;
  /* height: 3.6302rem; */
  width: 100%;
  padding: .2604rem 1.175rem;
  box-sizing: border-box;

}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 7.2917rem;
  margin: 0 auto;
  font-size: .0729rem;
  padding-bottom: 0.3646rem;

}

.container .left {
  height: 100%;
  width: 3.8542rem;
  margin-right: .640625rem
    /* 123/192 */
  ;
}

.left-title {}

.left-title div {
  color: white;
  font-size: .0729rem;
  /* line-height: .1042rem; */
  margin-bottom: .1042rem;
  text-align: left;
  font-size: .0833rem;
}

.left-title p {
  /* width: 3.8542rem;
  height: .375rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap; */
  font-size: .0729rem;
  color: #FFFFFF;
  opacity: 0.6;
  font-size: .0729rem;
  /* line-height: .125rem; */
}

.left-title p:hover {
  opacity: 1;
  color: white;
  cursor: pointer;
}

.leftBox {
  margin-top: .2083rem;
  padding: 0;
  box-sizing: border-box;
  font-size: .0729rem;
  display: flex;
  /* flex-direction: row-reverse; */
  flex-wrap: wrap;
}

.leftBox ul {
  /* display: inline-block; */
  
  /* width: 100px;
  max-width: 1.3rem; */
  text-align: left;
  vertical-align: top;
  padding: 0;
  margin-bottom: 0;
  margin-bottom: .2083rem;
  margin-right: .3rem;
}

/* .leftBox ul:nth-child(1) {
  margin-right: .2167rem;
  margin-bottom: .2083rem;
}

.leftBox ul:nth-child(2) {
  margin-right: .2896rem;
  margin-bottom: .2083rem;
}

.leftBox ul:nth-child(3) {
  margin-right: .6596rem;
  margin-right: .18rem;
  margin-bottom: .2083rem;
}

.leftBox ul:nth-child(4) {
  margin-bottom: .2083rem;
}

.leftBox ul:nth-child(5) {
  margin-right: .1596rem;
  margin-right: .23rem;
} */

.leftBox ul li {
  /* width: 70px; */
  min-width: 70px;
  font-size: .0625rem;
  /* line-height: .1042rem; */
  color: white;
  text-align: left;
  margin: 0 auto;
  opacity: 0.6;
  margin: .0833rem 0;
  /* text-overflow: ellipsis;
  overflow: hidden; */
  white-space: nowrap;
  cursor: pointer;
}

.leftBox ul li:hover {
  color: #ff7a00;
  opacity: 1;
}

/* 点击li后字体颜色变为白色 */
.active {
  color: white;
}

.leftBox ul li:nth-child(1) {
  color: white !important;
  margin-bottom: .1042rem;
  margin-top: 0;
  font-size: .0833rem !important;
  opacity: 1;
}

.leftBox ul li:nth-last-child(1) {
  margin: 0;

}

.container .right {
  height: 100%;
  /* width: 1.7188rem; */
  text-align: left;
}

.right-title {
  font-size: .0729rem;
  /* line-height: .1042rem; */
  color: white;

}

.right-box {
  margin-top: .1042rem;
}

.right-box .tel {
  font-size: .1042rem;
  line-height: .125rem;
  color: white;
}

.right-box .email {
  margin-top: .0833rem;
  font-size: .0729rem;
  /* line-height: .1042rem; */
  color: #fff;
  opacity: .6;
  cursor: pointer;
}

.right-box .email:hover {
  opacity: 1;
}

.right-box .address {
  margin-top: .0833rem;
  font-size: .0729rem;
  /* line-height: .1042rem; */
  color: #fff;
  opacity: .6;
  cursor: pointer;
  /* width: 1.7448rem; */
  margin-bottom: .2083rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-box .address:hover {
  opacity: 1;
}

.ewm {
  font-size: .0729rem;
  /* line-height: .1042rem; */
  color: white;
  opacity: 0.6;
  /* width: .474rem;
  height: .6208rem; */
  /* text-align: center; */
}

.ewm{
  width: .474rem;
  /* height: .474rem; */
  /* float: left; */
  margin-bottom: .0521rem;
  min-width: 100px;
  /* min-height:100px; */
  text-align: center;
}

.ewm img{
  width: 100%;
}

.Copyright{
  padding:.15625rem /* 30/192 */ ;
}

.footer{
  overflow: hidden;
}

.mobile{
  display: none;
}
.pc{
  display: block;
}


/* 手机端适应代码 */
@media screen and (max-width: 767px) {
  .mobile{
    display: block;
  }
  .pc{
    display: none;
  }
  .leftBox ul {
    width: 33.333%;
    text-align: left;
    vertical-align: top;
    padding: 0;
    margin-bottom: 0;
    margin-bottom: .2083rem;
    margin-right: 0px
  }
  .footer .content{
    width: 8.2917rem;
  }
  .footer{
    padding: 0.2604rem 1.175rem!important;
  }
  .container .left {
    margin-right: 0;
  }

  .content {
    flex-direction: column;
  }

  .left-title div,
  .leftBox ul li:nth-child(1),
  .right-box .tel,
  .right-title {
    font-size: 14px !important;
  }

  .left-title p,
  .leftBox ul li,
  .right-box .email,
  .right-box .email,
  .right-box .address,
  .Copyright,
  .ewm {
    font-size: 12px!important;
  }
  .ewm img{
    width: 100px;
    height: 100px;
  }

  .container .left{
    width: auto!important;
  }

}</style>